import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Premission } from '../models/premission.model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  permission: Premission[] = [];

  idPermisoNavegacion: number = Number(localStorage.getItem('auxId'));

  constructor() {
    this._abmOperations.next(true);
    this.getPermission();
  }

  getPermission() {
    const encryptedPermission = localStorage.getItem('permisos');
    if (encryptedPermission) {
      const decodedPermission = atob(encryptedPermission);
      const permission = JSON.parse(decodedPermission);
      this.permission = [...permission];
    }
  }

  _abmOperations = new Subject<boolean>();

  get AbmOperations() {
    return this._abmOperations.asObservable();
  }

  hasAccess(idPermiso: number) {
    return this.permission.some((i: Premission) => i.idPermiso === idPermiso);
  }

  getIdPermiso(): number {
    return this.idPermisoNavegacion;
  }

  setIdPermiso(idPermiso: number): void {
    localStorage.setItem('auxId', idPermiso.toString());
    this.idPermisoNavegacion = idPermiso;
  }
}
